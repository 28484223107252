<template>
  <div fluid>
    <!-- Table Top -->
    <b-card>
      <b-row>
        <b-col>
          <validation-observer v-slot="{ handleSubmit }">
            <b-form
              ref="myForm"
              class="p-2"
              @submit.prevent
            >
              <b-row>
                <b-col md="6">
                  <inpo-select :inpo-id.sync="liveData.entity_id" />
                </b-col>

                <b-col md="6">
                  <text-input
                    id="live-speaker"
                    name="Speaker"
                    :text.sync="liveData.speaker"
                  />
                </b-col>

                <b-col md="6">
                  <text-input
                    id="live-topic"
                    name="Topic"
                    rules="required"
                    :text.sync="liveData.activity_name"
                  />
                </b-col>

                <b-col md="6">
                  <validation-provider
                    #default="validationContext"
                    name="Live Description"
                  >
                    <b-form-group
                      label="Live Description"
                      label-for="live_des"
                    >
                      <b-form-input
                        id="live_des"
                        v-model="liveData.activity_description"
                        placeholder="Live Description"
                        :state="getValidationState(validationContext)"
                      />
                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <b-col md="6">
                  <text-input
                    id="live-link"
                    name="Link"
                    rules="required|url"
                    :text.sync="liveData.session_link"
                  />
                </b-col>

                <b-col md="6">
                  <language-select :languages.sync="liveData.languages" />
                </b-col>

                <b-col md="6">
                  <date-time
                    id="live-start-date-time"
                    :datetime.sync="liveData.session_start"
                    name="Start Date Time"
                    rules="required"
                    :config="{
                      enableTime: true,
                      dateFormat: 'Y-m-d H:i',
                      minDate: 'today',
                    }"
                  />
                </b-col>

                <b-col md="6">
                  <date-time
                    id="live-end-date-time"
                    :datetime.sync="liveData.session_end"
                    name="End Date Time"
                    rules="required"
                    :config="{
                      enableTime: true,
                      dateFormat: 'Y-m-d H:i',
                      minDate: $moment(liveData.session_start)
                        .add({ minutes: 1 })
                        .toDate(),
                    }"
                  />
                </b-col>

                <b-col md="6">
                  <validation-provider
                    #default="validationContext"
                    name="To Be Raised"
                    :rules="`integer|${liveData.entity_id ? 'required' : ''}`"
                  >
                    <b-form-group
                      label="To Be Raised"
                      label-for="to_be_raised"
                    >
                      <b-form-input
                        id="to_be_raised"
                        v-model="liveData.session_to_be_raised"
                        type="number"
                        placeholder="To Be Raised"
                        :state="getValidationState(validationContext)"
                      />
                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <b-col md="6">
                  <text-input
                    id="order"
                    name="Order"
                    type="number"
                    :text.sync="liveData.activityOrder"
                  />
                </b-col>

                <b-col
                  md="6"
                  class="my-2"
                >
                  <b-checkbox v-model="liveData.hide_poll_winner">
                    Hide poll winner
                  </b-checkbox>
                </b-col>

                <b-col
                  cols="12"
                  class="border mb-2 py-2"
                >
                  <h2>Custom Section</h2>
                  <b-row>
                    <b-col cols="12">
                      <rich-text-editor
                        name="Description"
                        rules="max:2048"
                        :description.sync="liveData.custom_section"
                      />
                    </b-col>
                    <b-col cols="12">
                      <b-row>
                        <b-col>
                          <date-time
                            id="custom-section-start-time"
                            :datetime.sync="liveData.custom_section_starts_at"
                            name="Custom Section Start Time"
                            :rules="`${
                              liveData.custom_section ? 'required' : ''
                            }`"
                            :config="{
                              enableTime: true,
                              dateFormat: 'Y-m-d H:i',
                              minDate: $moment(liveData.session_start)
                                .add({ minutes: 1 })
                                .toDate(),
                              maxDate: liveData,
                              session_end,
                            }"
                          />
                        </b-col>
                        <b-col>
                          <date-time
                            id="custom-section-end-time"
                            :datetime.sync="liveData.custom_section_ends_at"
                            name="Custom Section End Time"
                            :rules="`${
                              liveData.custom_section ? 'required' : ''
                            }`"
                            :config="{
                              enableTime: true,
                              dateFormat: 'Y-m-d H:i',
                              minDate: liveData.custom_section_starts_at,
                              maxDate: liveData.session_end,
                            }"
                          />
                        </b-col>
                      </b-row>
                    </b-col>
                  </b-row>
                </b-col>

                <b-col cols="12">
                  <div>
                    <upload-files
                      id="live-image"
                      label="Live Cover"
                      accept="image/png, image/jpeg"
                      :cropper="true"
                      :files.sync="liveData.cover_image"
                      rules="ext:jpg,jpeg,png|min-dimension:300,200|image-aspect-ratio:16,9"
                    >
                      <template #preview>
                        <div>
                          <images-preview :images.sync="liveData.cover_image" />
                        </div>
                      </template>
                    </upload-files>
                  </div>
                </b-col>

                <b-col
                  cols="12"
                  class="mt-2 text-right"
                >
                  <back class="mr-1" />
                  <submit-button
                    :handle-submit="handleSubmit"
                    :submit="addLive"
                  />
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import formValidation from '@core/comp-functions/forms/form-validation'

import InpoSelect from '@/common/components/LiveManagement/InpoLinkedWithStripeSelect.vue'
import handleAlerts from '@/common/compositions/common/handleAlerts'
import handleFormData from '@/common/compositions/common/handleFormData'
import Back from '@/common/components/common/FormInputs/Back.vue'
import LanguageSelect from '@/common/components/common/FormInputs/LanguageSelect.vue'
import SubmitButton from '@/common/components/common/FormInputs/SubmitButton.vue'
import UploadFiles from '@/common/components/common/FormInputs/UploadFiles.vue'
import ImagesPreview from '@/common/components/common/FormInputs/ImagesPreview.vue'
import TextInput from '@/common/components/common/FormInputs/TextInput.vue'
import DateTime from '@/common/components/common/FormInputs/DateTime.vue'
import RichTextEditor from '@/common/components/common/FormInputs/RichTextEditor.vue'

export default {
  name: 'AddLive',
  components: {
    InpoSelect,
    Back,
    LanguageSelect,
    SubmitButton,
    UploadFiles,
    ImagesPreview,
    TextInput,
    DateTime,
    RichTextEditor,
  },
  setup() {
    const { getValidationState } = formValidation()
    const { successfulOperationAlert } = handleAlerts()
    const { setFormData, formData } = handleFormData()

    return {
      getValidationState,
      successfulOperationAlert,

      setFormData,
      formData,
    }
  },
  data() {
    return {
      liveData: {
        activity_name: null,
        session_link: null,
        session_start: null,
        session_end: null,
        entity_id: null,
        activity_description: null,
        activity_type: null,
        activate_time: null,
        session_to_be_raised: null,
        cover_image: [],
        hide_poll_winner: false,
      },
    }
  },

  methods: {
    addLive() {
      this.setFormData({
        ...this.liveData,
        cover_image: this.liveData.cover_image[0],
      })
      return this.$activities
        .post('internalops/live', this.formData)
        .then(() => {
          this.successfulOperationAlert('Live is added successfully')
          this.$router.push({ name: 'live-management-list' })
        })
    },
  },
}
</script>

<style lang="scss">
</style>
